'use client' // this directive should be at top of the file, before any imports.

import React from 'react'
import { useRouter as useNextRouter } from 'next/router'
import classNames from 'classnames'
import { getStores } from 'flex-gateway-ssr/stores'
import { isServer } from 'flex-gateway-ssr/utils'

// import { FlexComponents } from 'flex-gateway-ssr/flex-components'
// const {
//   // Button,
//   // ButtonMarkup,
//   Link,
//   View,
//   Text,
//   Title,
//   TitleLevel,
//   TitleMarkup,
//   // VariantState,
//   styles
// } = FlexComponents

const {
  // Button,
  // ButtonMarkup,
  Link,
  View,
  Text,
  Title,
  TitleLevel,
  TitleMarkup,
  // VariantState,
  styles
} = await import('flex_design_system_react_ts_modfed/Styled')

const stores = getStores()
const { setNavigationState, setStatus } = stores.UIStore

const mf = `gateway-ssr-404`
const loadFlex = async () => {
  // loadable.lib(() => import('@flexiness/domain-lib-mobx-react-router').then((factory) => {
  //   const { getMFStore } = factory
  //   const MFStore = getMFStore()
  //   MFStore.setId(mf)
  //   MFStore.setBasename('/sorry-not-found')
  // }))
  import('@flexiness/domain-lib-mobx-react-router').then((factory) => {
    const { getMFStore } = factory
    const MFStore = getMFStore()
    MFStore.setId(mf)
    MFStore.setBasename('/sorry-not-found')
  })
}

const Custom404 = () => {
  const nextRouter = useNextRouter()

  // const hasMounted = useHasMounted()
  // if (!hasMounted) {
  //   return null
  // } else {
  //   setTimeout(function() {
  //     // setNavigationState('aside')
  //     // setStatus(null)
  //   }, 1000)
  //   if (!isServer) loadFlex()
  // }

  if (!isServer) loadFlex()
  setTimeout(function() {
    setNavigationState('aside')
    setStatus(null)
  }, 2000)

  return (
    <View className={classNames(
      'flex-view',
      styles.flexinessRoot,
      // styles.hasTextWhite
    )}>
      <div className='customError above-logo'>
        <Title level={TitleLevel.LEVEL3} markup={TitleMarkup.H1}
          style={{ color: 'white' }}>
          404 - Page Not Found
        </Title>
      </div>
      <div className='customError below-logo'>
        {/*
        <Button onClick={() => nextRouter.push('/home')}
          small inverted variant={VariantState.PRIMARY} markup={ButtonMarkup.BUTTON}>
          You probably want to head back here
        </Button>
        */}
        <Link inverted onClick={() => nextRouter.push('/home')}>
          <Text level={6}>
            You probably want to head back here
          </Text>
        </Link>
      </div>
    </View>
  )
}

export default Custom404
// export { Custom404 }
